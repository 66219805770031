const theme = localStorage.getItem('recruitee-theme') || 'light';

const setDataTheme = theme => {
  document.documentElement.setAttribute('data-theme', theme);
};

if (theme === 'system') {
  localStorage.setItem('recruitee-theme', 'auto');
}

if (theme !== 'auto') {
  setDataTheme(theme);
} else {
  window.matchMedia('(prefers-color-scheme: dark)').matches
    ? setDataTheme('dark')
    : setDataTheme('light');
}
